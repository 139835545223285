<template>
    <div class="pp-container">
        <div class="banner">
            <h2> قوانین و حریم خصوصی </h2>
        </div>
        <div class="text-container">
            <!-- <h2> قوانین </h2> -->
            <!-- <div class="text" v-html="privacyPolicy"></div> -->
            <div class="how-works">
                <h3> قوانین </h3>
                <p>
                    کارمزد معاملات انجام شده در کندل، بر اساس سطح کاربری از سفارش گذار و سفارش پذیر در زمان معامله کسر میگردد. باید در نظر داشت که افزایش سطح کاربری با در نظر 
                    گرفتن افزایش میزان معاملات ارتقا میابد. هم چنین بررسی میزان معاملات و افزایش سطح کاربری در هر ۲۴ ساعت یک بار به صورت اتومات انجام و سطوح کاربری ارتقا میابد. 
                    بر همین اساس سطوح کاربری و کارمزد 
                </p>
            </div>
            <div class="how-works">
                <h3> حریم خصوصی </h3>
                <p>
                    کارمزد معاملات انجام شده در کندل، بر اساس سطح کاربری از سفارش گذار و سفارش پذیر در زمان معامله کسر میگردد. باید در نظر داشت که افزایش سطح کاربری با در نظر 
                    گرفتن افزایش میزان معاملات ارتقا میابد. هم چنین بررسی میزان معاملات و افزایش سطح کاربری در هر ۲۴ ساعت یک بار به صورت اتومات انجام و سطوح کاربری ارتقا میابد. 
                    بر همین اساس سطوح کاربری و کارمزد 
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'PrivacyPolicy',
        data() {
            return {
                privacyPolicy:'',
            }
        },
        async mounted() {
            this.loading = true
            const res = await this.$axios.get('/exchange-info').catch(() => {
            })
            this.loading = false
            if (res && res.baseDTO) {
                this.privacyPolicy = res.baseDTO.privacyPolicy
            }
        }
    }
</script>

<style lang="scss" scoped>
.how-works{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    h3{
        font-weight: 700;
        font-size: clamp(24px,3vw,28px);
        color: var(--darker-primary);
    }
}
h2{
    font-weight: 700;
        font-size: clamp(24px,3vw,28px);
        color: var(--darker-primary);
        align-self: flex-start;
}
.text-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 72px;
    padding: 40px 120px;
}
.banner{
    background: url('../../assets/ppBanner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    padding: 50px 0;
    h2{
        font-weight: 700;
        font-size: clamp(28px,3vw,36px);
        color: var(--black);
        align-self: flex-end;
    }
}
.pp-container{
    display: flex;
    flex-direction: column;
    background: var(--homepage-bg);
    row-gap: 72px;
}
@media only screen and(max-width:1100px){
    .text-container{
        padding: 20px;
    }
    .banner{
        background: unset !important;
        height: 250px !important;
    }
}
</style>